window.realjQuery = jQuery;

jQuery(function ($) {
    var opendata = 0;
    var $opendatadatabox = $("#opendatadata");
    var regionsmap = {
        "chalteslandamelbstrom": "altes land am elbstrom",
        "chbraunschweigerland": "braunschweiger land",
        "chcuxland": "cuxland",
        "chduemmerweserland": "dümmerweserland",
        "chemsland": "emsland",
        "chflusslandschaftelbe": "flusslandschaft elbe",
        "chgoettingen": "göttingen",
        "chgrafschaftbentheim": "grafschaft bentheim",
        "chharz": "harz",
        "chkulturlandteufelsmoor": "kulturland teufelsmoor",
        "chlkhildesheim": "landkreis hildesheim",
        "chlkrotenburg": "landkreis rotenburg (w\u00fcmme)",
        "chlueneburgerheide": "lüneburger heide",
        "chmittelweser": "mittelweser",
        "chnaturwildeshausergeest": "wildeshauser geest",
        "chnordseekueste": "nordseeküste",
        "choldenburg": "oldenburg",
        "choldenburgermuensterland": "oldenburger münsterland",
        "chosnabrueckerland": "osnabrücker land",
        "chostfriesischeinseln": "ostfriesische inseln",
        "chostfriesland": "ostfriesland",
        "chsteinhudermeer": "steinhuder meer",
        "churlaubsregionhannover": "stadtgebiet hannover",
        "chwendelandelbe": "wendland.elbe",
        "chweserbergland": "weserbergland",
        "chwesermasch": "wesermarsch",
        "chlandkreisgoettingen": "landkreis göttingen",
        "chtano": "tano",
    };

    function generateopendatabox(region) {
        var totalrow = [];
        var openrow = [];
        var data = opendata[region];

        for (itemid in data.items) {
            var item = data.items[itemid];
            totalrow.push($(`<div class='datarow'><div class='datacount'>${item.count.total}</div><div class='dataname'>${item.title}</div></div></div>`));
            openrow.push($(`<div class='datarow'><div class='datacount'>${item.count.opendata}</div><div class='dataname'>${item.title}</div></div></div>`));
        }
        var title = $("<h3>").html("ALLE DATEN<br>" + data.title);
        $opendatadatabox.append(title);
        $opendatadatabox.append(totalrow);

        title = $("<h3>").html("<br>OPEN DATA");
        $opendatadatabox.append(title);
        $opendatadatabox.append(openrow);

        if (data.adressen){
            for (adresse of data.adressen) {
                var adresse = $(`<div><br>Open Data koordinierende Stelle: ${adresse.company}, Tel: ${adresse.tel}, <a href="mailto:${adresse.email}">${adresse.email}</a></div>`);
                $opendatadatabox.append(adresse);
            }
        }
    }

    if ($('.opendatakarteinfobox')){

        var $alleregionen = $('.regiohover');
        $alleregionen.click(function (event) {
            let $region = $(event.currentTarget);
            if ($region.hasClass("hover")){
                $alleregionen.removeClass("hover");
                $opendatadatabox.html("");
                $('#Clickparkplatz').empty();
                generateopendatabox("niedersachsen");

            }else{
                $alleregionen.removeClass("hover");
                $('#Clickparkplatz').empty();
                $opendatadatabox.html("");
                var region = regionsmap[event.currentTarget.id];
                generateopendatabox(region);
                $region.addClass("hover");
                var copyregion = $region.clone();
                copyregion.attr("id",copyregion.attr("id")+Math.random());
                copyregion.appendTo( "#Clickparkplatz" );
            }
        });
        $alleregionen.hover(function (event) {
            let $region = $(event.currentTarget);
            var copyregion = $region.clone();
            copyregion.attr("id",copyregion.attr("id")+Math.random());
            copyregion.addClass("hover");
            copyregion.appendTo( "#Hoverparkplatz" );
        }, function (event) {
            $('#Hoverparkplatz').empty();
        });

        function fetchRegions () {
            fetch ("https://nds.tourismusnetzwerk.info/wp-content/plugins/sf-opendata/counts.json")
                .then(response => {
                    if (!response.ok) {
                        throw new Error("HTTP error " + response.status);
                    }
                    return response.json();
                })
                .then(json => {
                    opendata = json;
                    generateopendatabox("niedersachsen");
                })
                .catch(function () {
                    this.dataError = true;
                })

        }

        fetchRegions();
    }
});